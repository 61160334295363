import http, { secondaryAxiosInstance } from '../httpConfig';

const getLoadingNumbers = ({ token, order, orderBy, page, perPage, carrierIdFilter }) => {
    let url = `/loading-number?order=${order}&orderBy=${orderBy}&page=${page}&perPage=${perPage}`
    if (carrierIdFilter) {
        url += `&carrierId=${carrierIdFilter}`
    }
    return http.get(url, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
};

const exportData = ({ token, carrierId }) => {
    return http.get(`/loading-number/export?carrierId=${carrierId}`, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
};

const importData = ({ token, payload }) => {
    return http.post(`/loading-number/import`, payload, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
};

const fetchSupplierFromLoadingNumber  = ({ token, payload }) => {
    return http.post(`/fetch-supplier/loading-number`, payload, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
};

const LoadingNumberService = {
    getLoadingNumbers,
    exportData,
    importData,
    fetchSupplierFromLoadingNumber
};

export default LoadingNumberService;